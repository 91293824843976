<template>
  <article class="main-page index" id='main-page' :class="$route.name">       
      <router-view />
      <footer style="position: relative;">
          <div class="wrapper" style="overflow: hidden">
              <div class="foot-outro">
                  <a href="/#/" id="foot_logo" class="logo">
         <img src="/img/logo@1x.svg" />
         <!--
                      <span class="logo-text-fixed"></span>
                      <div class="ico">
                          <span class="logo-arrow-from"></span
                          ><span class="logo-arrow-to hl"></span>
                      </div>
                      <span class="logo-text-float hl"></span>
         -->
                  </a>
                  <div class="foot-soc" v-if="false">
                      <!-- <span>Follow us:</span> -->
                      <a
                          href="https://twitter.com/fixedfloat"
                          title="Twitter"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="ico tw hoverhl"
                      ></a>
                      <!-- <a href="https://www.facebook.com/fixedfloat" title="Facebook" target="_blank" rel="noopener noreferrer" class="ico fb hoverhl"></a> --><a
                          href="https://t.me/FixedFloat"
                          title="Telegram"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="ico tg hoverhl"
                      ></a
                      ><a
                          href="https://medium.com/fixedfloat"
                          title="medium.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="ico medium hoverhl"
                      ></a
                      ><a
                          href="https://www.instagram.com/fixedfloat/"
                          title="instagram.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="ico instagram hoverhl"
                      ></a
                      ><a
                          href="https://www.reddit.com/user/FixedFloat/"
                          title="Reddit"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="ico reddit hoverhl"
                      ></a
                      ><a
                          href="https://bitcointalk.org/index.php?topic=5107342.new#new"
                          title="bitcointalk.org"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="ico bitcointalk hoverhl"
                      ></a
                      ><a
                          href="https://www.trustpilot.com/review/fixedfloat.com"
                          title="trustpilot.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="ico trustpilot hoverhl"
                      ></a>
                  </div>
                  <div class="copyright">
                      ￼ 2018–2024 <span>CCE Cash</span>. All rights
                      reserved.
                  </div>
              </div>
              <div class="fl">
                <div class="foot-menu linkhl">
                  <div class="col">
                      <h4>{{ $t("footer.CRYPTOMIXER") }}</h4>
                      <ul>
                          <li><a href="/#/main/readPage?page=about">{{ $t("menus.About") }}</a></li>
                          <!-- <li><a href="/#/brand">Our brand</a></li> -->
                          <!-- <li><a href="/#/affiliate">Affiliate program</a></li> -->
                          <!-- <li><a href="/addcoin">Add your coin</a></li> -->
                          <li class="faq"><a href="/#/main/readPage?page=terms-of-service">{{ $t("footer.TermsOfService") }}</a></li>
                          <li class="faq"><a href="/#/main/readPage?page=privacy-policy">{{ $t("footer.PrivacyPolicy") }}</a></li>
                      </ul>
                  </div>
                  <div class="col">
                      <h4>{{ $t("footer.Popular") }}</h4>
                      <ul >
                          <li v-if="false"><a href="/#/blog">Blog</a></li>
                          <li><a href="/#/main/home?from=USDT-TRC20&to=USDT-ERC20">USDT-TRC20 to USDT-ERC20</a></li>
                          <li class="faq1"><a href="/#/main/home?from=USDT-TRC20&to=ETH">USDT-TRC20 to ETH</a></li>
                          <li ><a href="/#/main/home?from=USDT-ERC20&to=ETH">USDT-ERC20 to ETH</a></li>
                          <li class="faq1"><a href="/#/main/home?from=ETH&to=BTC">ETH to BTC</a></li>
                          <li v-if="false"><a href="/#/lightning-faucet">Lightning Faucet</a></li>
                      </ul>
                  </div>
                  <div class="col">
                      <h4>{{ $t("menus.Support") }}</h4>
                      <ul >
                          <li><a href="/#/main/readPage?page=faq">{{ $t("menus.FAQ") }}</a></li>
                          <li class="faq"><a href="/#/main/support">{{ $t("menus.Support") }}</a></li>
                          <li v-if="false"><a href="/#/api">{{ $t("menus.API") }}</a></li>
                      </ul>
                  </div>
              </div>
              </div>
            
          </div>
      </footer>
  </article>
</template>


<script>
export default {
  name: "mainPage",
  methods: {},
  mounted(){
      console.log("router",this.$route);

  },
  watch: {
  $route: {
     handler:function(val, oldVal){
      this.$nextTick(function(){   //页面加载完成后执行
         window.innerWidth > 740 ? document.getElementById('logoImage').style.width='100%' : document.getElementById('logoImage').style.width='75%';
         var t = window.pageYOffset || document.documentElement.scrollTop;
         t > 0 ? document.getElementById('header').classList.remove('ontop') : document.getElementById('header').classList.add('ontop');
      })
     },
     // 深度观察监听
     deep: true
  }
  }
};

</script>



<style lang="less" scoped>


.main-page > footer{
  background: linear-gradient(90deg, #1F4172 0%, #122A5A 100%);

}

.foot-menu{
    display: block;
    font-size: 14px;
    // color: #8C91AF;
  }
  
  .foot-menu  ul{
    font-size: 12px;
  }
  .main-page > footer a {
    font-size: 12px;

  }
 
  a, .hl, .hoverhl:hover, .linkhl a:hover{
    color:#4790F6 ;
  }
 

.foot-menu .col{
  padding: 0;
}
@media screen and (max-width: 700px) {
  .main-page > footer .logo{
    padding-top: 22px;
    padding-bottom:12px ;
    margin: 0;
  }
  .main-page > footer{
    padding: 0px;
  }
  .copyright{
    margin: 0px;
  }
 img {
     width: 168px ;
  }
  .foot-menu{
    display: block;
    font-size: 14px;
    // color: #8C91AF;
  }
  
  .wrapper {
     padding: 0px 43px;
  }
  .foot-menu  ul{
    display: flex;
    font-size: 12px;
    flex-wrap: wrap;
  }
  .main-page > footer a {
    font-size: 12px;

  }
 
  a, .hl, .hoverhl:hover, .linkhl a:hover{
    color:#4790F6 ;
  }
  .faq{
    margin-left: 20px;
  }
  .faq1{
    margin-left: 20px;
  }
}

.foot-menu .col{
  padding: 0;
}



</style>